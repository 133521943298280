/** @format */

import "./App.scss";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, useParams } from "react-router-dom";

import VirtualEventView from "./containers/VirtualEvent/contentView/VirtualEventView";
import VirtualEventEditorView from "containers/VirtualEvent/editorView/virtualEventEditorView";

import { AUTH_HEADERS, encodeQueryParams } from "./util/api";
import { API_PATH, UNSUPPORTED_BROWSER_COOKIE, UNSUPPORTED_BROWSER_ACCEPTED, HOST } from "./constants";
import { connect, useDispatch } from "react-redux";

import { useAccessKey } from "containers/VirtualEvent/editorView/apiCalls";
import LoginForm from "LoginForm/loginForm";

import AppLocalizationProvider from "./AppLocalizationProvider";
import LoaderView from "LoaderView/loaderview";
import Bowser from "bowser";
import { Modal } from "react-bootstrap";
import { createCookie, getCookie } from "util/cookies";
import { Localized } from "@fluent/react";
import { Helmet } from "react-helmet";
import { usePossibleLanguages } from "util/localization";
import { ErrorPage } from "containers/VirtualEvent/editorView/errors";
import { MaintenancePage } from "containers/VirtualEvent/editorView/errors";
import ErrorHandler from 'components/ErrorHandler/errorHandler';
import { unlockAutoPlay } from "containers/VirtualEvent/contentView/header/helpers/settingsHelpers";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";

const mapDispatchToProps = dispatch => ({
	userFetched: user => dispatch({ type: "USER_FETCHED", payload: user }),
	eventFetched: event => dispatch({ type: "EVENT_FETCHED", payload: event })
});

const mapStateToProps = state => ({
	email: state.user.email,
	user: state.user
});

function useFetchEvent(saveEvent, slug, query) {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(null);
	const dispatch = useDispatch();
	useEffect(() => {
		async function fetchEvent() {
			setLoading(true);
			try {
				const response = await fetch(`${API_PATH}events/${slug}/${query}`);
				setStatus(response.status);

				const json = await response.json();
				if (response.ok) {
					saveEvent(json);
					dispatch({ type: "SET_EDITOR_LANGUAGE", payload: json.languages[0] });
				} else {
				}
			} catch (error) {
			} finally {
				setLoading(false);
			}
		}
		fetchEvent();
	}, [query, saveEvent, slug]);

	return [loading, status];
}

function useFetchUser(saveUser, slug) {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState(null);
	useEffect(() => {
		async function fetchUser() {
			setLoading(true);
			try {
				const response = await fetch(`${API_PATH}users/me/?event=${slug}`, {
					headers: AUTH_HEADERS
				});
				setStatus(response.status);
				const json = await response.json();
				json.guestuser = json.type === "guest" ? true : false;

				if (response.ok) {
					saveUser(json);
				}
			} catch (error) {
			} finally {
				setLoading(false);
			}
		}
		fetchUser();
	}, [saveUser]);
	return [loading, status];
}

export const saveVisit = async (slug, chat_user_id, access_key) => {
	const query = encodeQueryParams({ access_key });
	const visitEndpoint = `${HOST}api/v1/events/${slug}/save_visit/${query}`;
	// Registering attendee's visit for the attendance management
	const visitBody = { platform: "virtual" };
	const response = await fetch(visitEndpoint, {
		headers: AUTH_HEADERS,
		body: JSON.stringify(visitBody),
		method: "POST"
	});
	if (response.ok) {
		sessionStorage.setItem("visited", chat_user_id);
	} else console.error("Error saving attendee visit");
};

const App = props => {
	const { eventFetched, userFetched } = props;
	const query = useAccessKey();
	const { slug } = useParams();
	const [eventLoading, eventLoadingStatus] = useFetchEvent(eventFetched, slug, query);
	const [userLoading, userLoadingStatus] = useFetchUser(userFetched, slug);
	/*
        Should cookie be refreshed if it already exists?

        Flow:
        -> Try to fetch user, if failed -> 
            Open authentication -> Authenticate -> Try to fetch user info again
            
        -> On fetch user success continue to progress to the event

        -> If event is found -> Progress to event, else show fallback page
    */

	const loading = [userLoading, eventLoading].some(load => load === true);
	const possibleLanguages = usePossibleLanguages();

	useEffect(() => {
		unlockAutoPlay();
	}, []);

	let renderComponent = (
		<Router>
			<Switch>
				<Route path={`/:slug/:room_slug/editor`}>
					<VirtualEventEditorView />
				</Route>
				<Route path={`/:slug/:room_slug?`}>
					<VirtualEventView />
				</Route>
			</Switch>
		</Router>
	);

	if (eventLoadingStatus === 500) renderComponent = <MaintenancePage />;
	else if (eventLoadingStatus !== 200)
		renderComponent = <ErrorPage status={404} title={<Localized id="error-event-not-found" />} logo={true} />;
	else if (userLoadingStatus !== 200) renderComponent = <LoginForm query={query} event_slug={slug} />;

	if (loading) return <LoaderView loadingContent="Loading event.." />;

	return (
		<AppLocalizationProvider possibleLanguages={possibleLanguages} eventSlug={slug}>
			<MantineProvider theme={{ fontFamily: "Roboto" }}>
				<NotificationsProvider>
					<BrowserDetector>
						<Helmet>
							<link
								rel="stylesheet"
								type="text/css"
								href={`${HOST}api/v1/events/${slug}/lobby/style.css/`}
							/>
						</Helmet>
						<CookieConsent />
						{renderComponent}
					</BrowserDetector>
				</NotificationsProvider>
			</MantineProvider>
		</AppLocalizationProvider>
	);
};

const CookieConsent = props => {
	const cookieExists = getCookie("cookie_consent");
	const [show, setShow] = useState(cookieExists ? false : true);

	function addCookie(value) {
		createCookie("cookie_consent", value, 365);
		setShow(false);
	}

	if (show) {
		return (
			<div id="cookie-consent">
				<div>
					<Localized id="cookie-consent-explanatory">
						{
							"This website uses cookies to provide you with the best experience. By continuing to visit this site you agree to our use of cookies."
						}
					</Localized>
				</div>
				<div className="button-container">
					<button className="accept-cookies" onClick={() => addCookie(true)}>
						<Localized id="cookie-consent-accept">{"Accept"}</Localized>
					</button>
					<button className="deny-cookies" onClick={() => addCookie(false)}>
						<Localized id="cookie-consent-deny">{"Deny"}</Localized>
					</button>
				</div>
			</div>
		);
	}
	return null;
};

const BrowserDetector = props => {
	const browser = Bowser.getParser(window.navigator.userAgent);
	const isValidBrowser = browser.satisfies({
		mobile: {
			safari: ">=13.4"
		},
		chrome: ">=64",
		firefox: ">31",
		edge: ">=79",
		safari: ">=13.1"
	});
	const [showBrowserModal, setShowBrowserModal] = useState(false);
	useEffect(() => {
		const accepted = getCookie(UNSUPPORTED_BROWSER_ACCEPTED);
		if (!isValidBrowser && !accepted) {
			createCookie(UNSUPPORTED_BROWSER_COOKIE, true, 365);
			setShowBrowserModal(true);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Modal
				show={showBrowserModal}
				backdrop="static"
				onHide={() => setShowBrowserModal(false)}
				centered
				contentClassName="browser-modal"
			>
				<Modal.Header>
					<h3>
						<Localized id="browser-modal-heading">{"Käytät tukematonta selainta"}</Localized>
					</h3>
				</Modal.Header>

				<Modal.Body className="browser-modal-body">
					<code className="code-block">
						<Localized
							id="browser-modal-version"
							vars={{
								browserName: browser.getBrowserName(),
								browserVersion: browser.getBrowserVersion()
							}}
						>
							{"Käytössänne on: " + browser.getBrowserName() + " " + browser.getBrowserVersion()}
						</Localized>
					</code>
					<p>
						<Localized id="browser-modal-suggest-chrome">
							{
								"Suosittelemme käyttäjiämme käyttämään Google Chrome -selainta. Voit ladata viimeisimmän Google Chrome -version. Voit ladata google chromen viimeisimmän version googlen kotisivuilta."
							}
						</Localized>
					</p>
					<p>
						<b>
							<Localized id="browser-modal-notification">
								{"Jotkin ominaisuudet eivät välttämättä toimi oikein tällä selainversiolla."}
							</Localized>
						</b>
					</p>
				</Modal.Body>

				<Modal.Footer>
					<button
						onClick={() => {
							createCookie(UNSUPPORTED_BROWSER_ACCEPTED, true, 365);
							setShowBrowserModal(false);
						}}
					>
						<Localized id="browser-modal-button">{"Ymmärrän, haluan jatkaa"}</Localized>
					</button>
				</Modal.Footer>
			</Modal>
			{props.children}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
